@font-face {
    font-family: myFirstFont;
    src: url(../Competitions/FontsFree-Net-EquinoxRegular.ttf);
}

.regcompProfileHead {color:#00d0ff;font-weight: bold;font-size: 22px;letter-spacing: 1px;}
.regcompProfileSpan {color:#fff; font-size: 22px;}

.main-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height:100%;
}

.profile-contestDetailHead {
    font-family: Montserrat;
	font-size: 2.5rem;
	font-weight: bold;
	margin-bottom: 10px;
}

.profile-contest-details-background {
	background-image: url("../Dashboard/jason-dobkin-robot-transport-2.jpg");
}

.profile-contest-details-container {
	margin:auto;
	position:relative;
}

.profile-contest-details-tabs {
	position:absolute;
	left: 150px;
	width: 580px;
}

.profile-contest-details-tabs * {
	text-transform: uppercase;
	font-size: 1rem;
}

.profile-contest-details-tabs .MuiTabs-scroller {
	width: 100%;
}

.profile-contest-details-tabs .MuiTabs-indicator {
	background-color: #24B9DB;
}

.profile-contest-details-inner {
	display: inline-block;
	width: 500px;
}

.dash-button {
	transition: filter 0.1s ease-out;
	position:absolute;
	border: none;
	background-color: rgba(0,0,0,0);
	text-transform:uppercase;
}

.profile-contest-details-container .profile-contest-details-buttons button span{
	float:left;
}

.profile-contest-details-container .profile-contest-details-buttons button:hover {
	filter:brightness(2);
}


.profile-contest-details-register {
	bottom: 0px;
	left: 142px;
	width: 187px;
    height: 58px;
	background-image: url("../Competitions/subpages/vectors/button1.svg");
	background-size: cover;
    background-repeat: no-repeat;
}

.profile-contest-details-register span {
	margin-left:45px;
}

.profile-contest-details-other {
	bottom: 0px;
	left: 310px;
	width: 178px;
    height: 58px;
	background-image: url("../Competitions/subpages/vectors/button2.svg");
	background-size: cover;
    background-repeat: no-repeat;
}

.dash-button div {
	background-size:cover;
	left: 0;
	bottom: 0;
	width: 0;
	transition: all 1s cubic-bezier(0.17, 0.84, 0.44, 1);
	filter: brightness(2);
}

button.dash-button:hover div {
	width: 100%;
}

.profile-contest-details-other span{
	margin-left: 55px;
}

.profile-contest-details-ps {
	bottom: 0px;
	left: 467px;
	width: 282px;
	height: 58px;
	background-image: url("../Competitions/subpages/vectors/button3.svg");
	background-size: contain;
    background-repeat: no-repeat;
}

.profile-contest-details-ps span {
	margin-left: 50px;
}

.profile-contest-details-content {
	width: 866px;
	height: 500px;
	padding: 40px 40px 40px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	background-image: url("../Competitions/subpages/vectors/container.svg");
	background-size: contain;
    background-repeat: no-repeat;
}

/* .profile-contest-details-content * {
	margin: 0 10px;
} */

.profile-contest-details-img {
	display:inline-block;
	float:right;
	width: 200px;
	height: 200px;
	background: linear-gradient(
		169.15deg, 
		rgba(17, 64, 63, 0.4) 0%, 
		rgba(17, 64, 63, 0.2) 96.79%),
				radial-gradient(
					100% 100% at 0% 0%, 
					rgba(255, 255, 255, 0.4) 0%, 
					rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

	position:relative;
	top: 50px;
	left: 50px;
	border: solid 1px #3DE8E6;
	border-radius: 5px;
}

.profile-contest-details-img img {
	width: 200px;
	height: 200px;
	position: absolute;
	top: -50px;
	left: -50px;
	border: solid 1px #3DE8E6;
	border-radius: 5px;
}

.profile-contest-details-em {
	color: #3DE8E6;
}

.profile-contest-details-blob {
    position: absolute;
    margin-right: 40%;
    margin-bottom: 30%;
    width: 50%;
    height: 60%;
    background: linear-gradient(
      180deg,
      #009F8C 31.77%,
      #019C89 100%
    );
    mix-blend-mode: color-dodge;
    -webkit-animation: contest-details-blob-move 25s infinite alternate;
            animation: contest-details-blob-move 25s infinite alternate;
    transition: 3s cubic-bezier(0.07, 0.8, 0.16, 1);
}

.profile-contest-details-blob:hover {
  width: 60%;
  height: 60%;
  -webkit-filter: blur(30px);
		  filter: blur(30px);
  box-shadow:
	inset 0 0 0 5px rgba(255,255,255, 0.6),
	inset 100px 100px 0 0px #00566C,
	inset 200px 200px 0 0px #07a4aa,
	inset 300px 300px 0 0px #2b86c5;
}

@media (min-width: 600px) and (max-width: 900px) {
	.MuiBox-root {
		position:relative;
		height: 100%;
	}

	.profile-contest-details-container {
		margin:auto;
		padding: 50px 10px;
		position:relative;
		width: 100%;
		height: 100%;
		display:flex;
		flex-direction: column;
		justify-content: space-between;
		align-items:center;
		overflow:auto;
	}

	.profile-contest-details-tabs {
		position:relative;
		left: auto;
		width: 90%;
		border: 0.5px solid #3DE8E6;
		background-color: #131E2B;
	}
	
	.profile-contest-details-inner {
		width: calc(100% - 260px);
	}
	
	.profile-contest-details-container > .profile-contest-details-buttons button {
		transition: filter 0.1s ease-out;
		position:relative;
		border: none;
		border: 0.5px solid #3DE8E6;
		background-color: #131E2B;
		text-transform:uppercase;
	}
	
	.dash-button {
		position: relative;
		border: 0.5px solid #3DE8E6;
		margin-right: 5px;
	}
	
	.dash-button div {
		border: none !important;
		background-color: rgba(255, 255, 255, 0.1) !important;
	}
	
 	.profile-contest-details-content {
		width: 100%;
		padding: 10px;
		/* margin-top: 50px; */
		justify-content: space-between;
		border: 0.5px solid #3DE8E6;
		background-color: #131E2B;
		background-image: none;
	}
 	.profile-contest-details-buttons {
 		width: 90%;
 		display:flex;
 		justify-content:space-between;
 		align-items:center;
 	}

	.profile-contest-details-register {
		position: absolute;
		bottom: -60px;
		left: 0;
		width: 30%;
		background: none;
	}

	.profile-contest-details-register span {
		margin-left:auto;
	}

	.profile-contest-details-other {
		position: absolute;
		bottom: -60px;
		left: 70%;
		width: 30%;
		background: none;
	}

	.profile-contest-details-other span{
		margin-left: auto;
	}

	.profile-contest-details-ps {
		bottom: auto;
		left: auto;
		width: 30%;
		background: none;
	}

	.profile-contest-details-ps span {
		margin-left: auto;
	}

	/* .profile-contest-details-content * {
		margin: 0 10px;
	} */

	.profile-contest-details-img {
		width: 150px;
		height: 150px;
		top: 50px;
		left: auto;
		border: solid 1px #3DE8E6;
		border-radius: 5px;
		flex-shrink: 0;
	}

	.profile-contest-details-img img {
		width: 150px;
		height: 150px;
		position: absolute;
		top: -50px;
		left: -50px;
		border: solid 1px #3DE8E6;
		border-radius: 5px;
	}

	.profile-contest-details-em {
		color: #3DE8E6;
	}

}

@media (max-width: 600px) {

	.hospySectionH {
		height: auto !important;
	}

	.MuiBox-root {
		position:relative;
		height: 100%;
	}

	.profile-contest-details-container {
		margin:auto;
		padding: 50px 10px;
		position:relative;
		width: 100%;
		height: 100%;
		display:flex;
		flex-direction: column;
		justify-content: space-between;
		align-items:center;
		overflow:auto;
	}

	.profile-contest-details-tabs {
		position:relative;
		left: auto;
		width: 90%;
		border: 0.5px solid #3DE8E6;
		background-color: #131E2B;
	}

	.regCompTabpanel {
		height: 100% !important;
	}
	.regcompTeamDetail {
		flex-direction: column;
	}
	.profile-contest-details-inner {
		width: 90%;
		height: 100% !important;
	}
	
	.profile-contest-details-container > .profile-contest-details-buttons button {
		transition: filter 0.1s ease-out;
		position:relative;
		border: none;
		border: 0.5px solid #3DE8E6;
		background-color: #131E2B;
		text-transform:uppercase;
	}
	
	.dash-button {
		position: relative;
		border: 0.5px solid #3DE8E6;
		margin-right: 5px;
	}
	
	.dash-button div {
		border: none !important;
		background-color: rgba(255, 255, 255, 0.1) !important;
	}
	
 	.profile-contest-details-content {
		width: 100%;
		padding: 20px;
		/* margin-top: 50px; */
		justify-content: space-between;
		flex-direction: column;
		border: 0.5px solid #3DE8E6;
		background-color: #131E2B;
		background-image: none;
		overflow:auto;
	}
 	.profile-contest-details-buttons {
 		width: 90%;
 		display:flex;
 		justify-content:space-between;
 		align-items:center;
 	}

	.profile-contest-details-register {
		position: absolute;
		bottom: -60px;
		left: 0;
		width: 30%;
		background: none;
	}

	.profile-contest-details-register span {
		margin-left:auto;
	}

	.profile-contest-details-other {
		position: absolute;
		bottom: -60px;
		left: 35%;
		width: 30%;
		background: none;
	}

	.profile-contest-details-other span{
		margin-left: auto;
	}

	.profile-contest-details-ps {
		bottom: auto;
		left: auto;
		width: 30%;
		background: none;
	}

	.profile-contest-details-ps span {
		margin-left: auto;
	}

	/* .profile-contest-details-content * {
		margin: 0 10px;
	} */

	.profile-contest-details-img {
		display:none;
	}

	.profile-contest-details-img img {
		display:none;
	}

	.profile-contest-details-em {
		color: #3DE8E6;
	}

}

@-webkit-keyframes contest-details-blob-move {
  from {
	transform: translate(-100px, -50px) rotate(-90deg);
	border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
	transform: translate(500px, 100px) rotate(-10deg);
	border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

@keyframes contest-details-blob-move {
  from {
	transform: translate(-100px, -50px) rotate(-90deg);
	border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
	transform: translate(500px, 100px) rotate(-10deg);
	border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}


.question{
	font-size: 20px;
	font-weight: 700;
}

.answer{
	font-size: 20px;
	font-weight: 700;
		 
		color:#3DE8E6 ;
}