img {
  max-width: 100%;
  vertical-align: top;
}

.brochure-gallery {
    display: flex;
    margin: 10px auto;
    /* height: 60px; */
    height: 100%;
    /* max-width: 800px; */
    position: relative;
    padding-top: 66.6666666667%;
    width: 54vw;
    position: absolute;
    bottom: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    /* left: 10px; */
    margin: 0 auto;
     left:23%;
/*transform: translateX(-50%); */
    /* transform: translateX(50%); */
}
@media screen and (min-width: 600px) {
  .brochure-gallery {
    padding-top: 400px;
  }
}
@media screen and (max-width: 900px){
    .brochure-gallery__img{
        width: fit-content !important;
    }
}
.gallery__img {
  position: fixed;
  /* top: 0;
  left: calc(100vw / 2); */
  /* transform: translateX(50%); */
  top: 50%;
left: 50%;
transform: translate(-50%, -50%);
  opacity: 0;
  /* padding-top: 75px; */
  /* padding-left: auto; */
  transition: opacity 0.3s ease-in-out;
  /* max-width: 800px; */
  width: fit-content;
  max-height: 77vh;
}
.gallery__thumb {
  padding-top: 6px;
  margin: 6px;
  display: block;
  position: absolute;
  bottom: 10px;
  left: 100px;
}
.gallery__selector {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.gallery__selector:checked + .gallery__img {
  opacity: 1;
}
.gallery__selector:checked ~ .gallery__thumb > img {
  box-shadow: 0 0 0 3px #0be2f6;
}/*# sourceMappingURL=brochure.css.map */

.dep-gallery-bottom{
    /* position: fixed; */
    bottom: 24px;
    width: 100%;
}