@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fira+Sans:wght@500&family=Lato:wght@700&family=Orbitron&family=Pacifico&family=VT323&family=Work+Sans:wght@200&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fira+Sans:wght@500&family=Lato:wght@700&family=Orbitron&family=Pacifico&family=Poppins:wght@200&family=Roboto+Condensed&family=VT323&family=Work+Sans:wght@200&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fira+Sans:wght@500&family=Lato:wght@700&family=Orbitron&family=Oswald:wght@300&family=Pacifico&family=Poppins:wght@200&family=Roboto+Condensed&family=VT323&family=Work+Sans:wght@200&display=swap');
.Dashboard {
    display: flex;
    /* align-items: center; */
    height: 100%;
    width: 100%;
    justify-content: flex-start;
}

/* .Dashboardfullscreen { */
    /* height: calc(100vh - 100px); */
    /* background: url("https://images.unsplash.com/photo-1624280433509-b01afeaf68e5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1915&q=80"); */
/* 
    background: url("peakpx\ \(3\).jpg");
    background-size: 100vw 100vh;
    background-position: fixed;
 */
/* } */

@media only screen and (max-width: 600px) {
    .card_book_outer {
        top: 25px;
        width: 100%;
        height: 100%;
        padding: 30px;
	    display: flex;
	    flex-direction: column;
	    justify-content: space-between;
	    text-align: justify;
        background: none !important;
        padding: 0px !important;
    }

    .card_book {
        width: 95% !important;
        height: 100%;
        background-repeat: no-repeat;
        border-radius: 10px;
        border: solid 1px #888;
        background-size: contain;
        margin: auto;
        background: rgba(255, 255, 255, 0.17) !important;
        text-align: justify !important;
        padding: 0 0 !important;
    }

    .dashboard2-content{
        display: grid !important;
        grid-template-columns: 1fr;
        grid-template-rows: 0.75fr 1fr;
        gap: 0px 0px;
        grid-template-areas:
        "dashboard2-image"
        "dashboard2-text";
    }

    .dashboard2-image {
        grid-area: dashboard2-image;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height: auto !important;
        padding-block: auto;
    }

    .dashboard2-image img {
        border-radius: 50%;
        right: 10px;
        width: 150px;
        padding: 10px;
    }

    .dashboard2-image h1{
        width: 60%;
        margin: 5px;
        display: flex;
        font-size: 35px;
        flex-direction: column;
        font-weight: bold;       
    }

    .dashboard2-text {
        grid-area: dashboard2-text;
        display: flex;
        flex-direction: column;
        width: 100%;
        height:100%;
        border-radius: 10px;
        margin: 10px auto 10px 80px;
        font-size: 20px !important;
    }

    .dashboard2-update-profile {
        background: none !important;
        border: 1px solid #888 !important;
        margin-bottom: -120px ;
        position: absolute;        
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }

    .dashboard2-log-out {
        background: none !important;
        border: 1px solid #888 !important;
        margin-bottom: -200px ;        
        position: absolute;        
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}
.register-box-inner{
/* 	background-image: url("Vector\ 11.svg"); */
    width: 780px;
    height: 380px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
/* 
    position: absolute;
    transform: translate(1%, 4%);
 */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 17px;
}

.dashboard-page-background {
	background-image: url("jason-dobkin-robot-transport-2.jpg");
    position:fixed;
	width:100%;
	height:100%;
	top:0;
	left:0;
	background-attachment: fixed;
	background-size: cover;
	background-position: center;
	z-index:-1;
	
}

.dashboard-profileBackground {
/* 
    background: url("peakpx\ \(3\).jpg");
    background-size: cover;
    background-position: fixed;
 */
    /* top: 0; */
    /* left: 0; */
    /* height: 100vh; */
    /* width: 100vw; */
    /* z-index: -90; */
}

/* .DashboardBox{
} */
.dashboard-content {
    display: flex;
    flex-direction: column;
    width: 35%;
    height:100%;
    /* border-radius: 10px; */
    /* position: absolute; */
    
    /* border: 2px solid red; */
    border-radius: 10px;
    margin: 10px auto 10px 80px;
    overflow-wrap: break-word;
}

.dashboard-image {
    /* position: absolute; */
    /* right: 0px; */
    /* top: 0px; */
    /* width: 200px; */
    /* height: 200px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.dashboard-image img {
    border-radius: 50%;
    /* border-top-right-radius: 9%;
    border-bottom-left-radius: 9%; */
    /* position: absolute; */
    top: 10px;
    right: 10px;
    width: 150px;
    padding: 10px;

    /* transform: scale(0.3); */
}

.dashboard-image h1{
    width: 60%;
    margin: 5px;
    display: flex;
    font-size: 35px;
    flex-direction: column;
    font-weight: bold;
    
}
.dashboard-image h1 span{
    text-align: center;
}

.dashboard-text {
    /* position: absolute; */
    /* left: 0; */
    /* top: 0; */
    text-align: left;
    height: 100%;
    width: 100%;
    -webkit-backdrop-filter: blur(8px);  /* Safari 9+ */
backdrop-filter: blur(8px); /* Chrome and Opera */
box-shadow: inset 0 0 0 2000px rgba(58, 247, 251, 0.08);
/* font-family: 'Roboto Condensed', sans-serif !important; */
font-family: 'Oswald', sans-serif !important;
}

.dashboard-text h1 {
    margin: 5px 5px;
}

.dashboard-text h1 span {
    color: rgb(0, 225, 255);
font-family: 'Oswald', sans-serif !important;

}

.dashboard-info {
    /* position: absolute; */
    /* bottom: 10px; */
    font-size: 20px;
    /* left: 10px; */
    font-family: 'Orbitron', sans-serif;
    font-weight: bold;
    padding: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.dashboard-info span span {
    color: aquamarine;
    overflow-wrap: break-word;
}

.card_book_outer{
    color: aliceblue;
    background: url("Vector 10 (3).svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
}

.card_book
{   display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color:#0e1a26; */
    /* border-color:#057c9a; */
    width: 850px;
    /* height: 60%; */
    aspect-ratio: 2/1.3;
    /* padding: 100px 0; */
    color: aliceblue;
    /* background: url("Vector 11 (1).svg"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
        }

.Dashboard2{
    display: flex;
    align-items: center;
    justify-content: center;
    /* backdrop-filter:blur(10px); */
}

.dashboard2-content{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    /* height: 200%; */
    padding: 10px;
    /* height: 10%; */
    font-size: 26px;
    overflow-wrap: break-word;
    gap: 10px;
}
.dashboard2-text{
    height: 100%;
    max-width: 60%;
}
.dashboard2-image{
    /* width: 100%; */
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    
}
.dashboard2-image img{
    height: 80%;
    border-radius: 10px;
}

.dashboard2-info{
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.dashboard2-info >span >span{
    /* border-bottom: 2px solid white; */
    color: aqua;
}

.dashboard2-update-profile{
    background: url("Asset\ 6.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    position: absolute;
    bottom: 25px;
    left: 128px;
    font-weight: bold;
}
/* .dashboard2-log-out{
    background-size: 200px 50px;
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    position: absolute;
    bottom: 37px;
    left: 226px;
    bottom: 22px;
    left: 226px;
    font-weight: bold;
    font-size: 20px;
} */

.dashboard2-log-out {
    background: url("Asset\ 7.svg");
    background-size: 114px 50px;
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    position: absolute;
    bottom: 37px;
    left: 242px;
    bottom: 22px;
    left: 296px;
    width: 114px;
    font-weight: bold;
    /* font-size: 20px; */
}

.css-1lz1uin-MuiTabs-root {
    overflow: hidden;
    min-height: 48px;
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 781px;
    height: 100%;
    color: white;
    height: 30px;
    border: 2px solid #0be1e5;
    border-radius: 10px;
}

.css-1lz1uin-MuiTabs-root {
    overflow: hidden;
    min-height: 48px;
    -webkit-overflow-scrolling: touch;
    display: flex;
    width: 781px;
    height: 100%;
    color: white;
    height: 30px;
    border: 2px solid #0be1e5;
    border-radius: 10px;
    justify-content: center;
}

.css-1fq8pu5 {
    /* border-bottom: 1px solid; */
    border-color: #20b2aa;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 55px;
    width: 60%;
    margin: auto;
}

.css-1dha5qx-MuiTabs-flexContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    /* width: 400px; */
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.css-1fq8pu5 {
    border-bottom: 0px solid;
    border-color: #20b2aa;
    display: flex;
    height: 55px;
    width: 60%;
    margin: auto;
    align-items: center;
    justify-content: center;
}

hr.sep-2 {
    border: 0; 
    height: 1px; 
    background-image: linear-gradient(to right, #f0f0f0, #00b9ff, #59d941, #f0f0f0);
  }


.member-names{
    border: 1px;
}


/* CSS */
.button-17 {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.button-17:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

@media screen and (max-width: 1000px) {
    .dashboard-content {
    	margin: 50px auto;
        width: 90vw;
        overflow-wrap: break-word;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
    }
    
    .dashboard-text {
        font-size: 20px;
        position: static;
    }
    
    .dashboard-info {
        font-size: 24px;
        position: static;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .dashboard-text h1 {
        text-align: center;
    }

    .dashboard-image {
        /* margin: 0 calc(45vw - 75px); */
        position: static;
        height: 278px;
        display: flex;
        flex-direction: column;
    }

    .dashboard-image img {
        top: 20px;
        border-radius: 10%;
    }
    .dashboard-image h1{
        width: 100%;
    }
}