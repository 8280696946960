@font-face{font-family:equinox;font-style:normal;font-weight:500;src:local('Equinox'),url(Equinox.ttf) format('ttf')}

@font-face {
    font-family: myFirstFont;
    src: url(FontsFree-Net-EquinoxRegular.ttf);
}



*{
    font-family: 'Montserrat', sans-serif;
    color:#fff;
}

:root {
	color-scheme:dark;
	overflow: hidden;
}

img {
	object-fit: cover;
	object-position:center;
}

.headEver {
	font-family: "myFirstFont"
}

.talkhead {
	font-size:5rem;
}

.page-background {
	position:fixed;
	width:100%;
	height:100%;
	top:0;
	left:0;
	background-attachment: fixed;
	background-size: cover;
	background-position: center;
	z-index:-1;
}


#myVideo {
    bottom: 0;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    right: 0;
    width: 100vw;
    z-index: -1;
}

/* * {
scrollbar-width:none;
-ms-overflow-style:none;
} */

*::-webkit-scrollbar {
/* display:none; */
/* scrollbar-color: red white; */
/* color: white; */
}

*::-webkit-scrollbar {
	width: 0.2em;
	height: 0.2em;
  }
   
  *::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  *::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
  }

@font-face {
  font-family: BlackPast;
  src: local("BlackPast"),
  url("./Components/Contact/BlackpastDemo-vm2l9.ttf") format("truetype");
}

.suspense-fallback-loader {
	width:100px;
	height:100px;
	margin:auto;
	border-radius:50%;
	border: 20px dashed white;
	animation: 1s suspense-loader-anim linear infinite;
}

@keyframes suspense-loader-anim {
	100% {
		transform: rotate(360deg);
	}
}

.glass {
	color:#fff;
	background-color:rgba(18, 31, 46, 0.85);
	border:solid 1px #fff;
/* 	background-color:#121F2E; */
}









/* Individual Caption Styles */

/* Caption Style 1 */
.cs-style-1 figcaption {
	height: 100%;
	width: 100%;
	opacity: 0;
	text-align: center;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	-moz-transition: -moz-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
}

.no-touch .cs-style-1 figure:hover figcaption,
.cs-style-1 figure.cs-hover figcaption {
	opacity: 1;
	-webkit-transform: translate(15px, 15px);
	-moz-transform: translate(15px, 15px);
	-ms-transform: translate(15px, 15px);
	transform: translate(15px, 15px);
}

.cs-style-1 figcaption h3 {
	margin-top: 70px;
}

.cs-style-1 figcaption span {
	display: block;
}

.cs-style-1 figcaption a {
	margin-top: 30px;
}

/* Caption Style 2 */
.cs-style-2 figure img {
	z-index: 10;
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: -moz-transform 0.4s;
	transition: transform 0.4s;
}

.no-touch .cs-style-2 figure:hover img,
.cs-style-2 figure.cs-hover img {
	-webkit-transform: translateY(-90px);
	-moz-transform: translateY(-90px);
	-ms-transform: translateY(-90px);
	transform: translateY(-90px);
}

.cs-style-2 figcaption {
	height: 90px;
	width: 100%;
	top: auto;
	bottom: 0;
}

.cs-style-2 figcaption a {
	position: absolute;
	right: 20px;
	top: 30px;
}

/* Caption Style 3 */
.cs-style-3 figure {
	overflow: hidden;
}

.cs-style-3 figure img {
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: -moz-transform 0.4s;
	transition: transform 0.4s;
}

.no-touch .cs-style-3 figure:hover img,
.cs-style-3 figure.cs-hover img {
	-webkit-transform: translateY(-50px);
	-moz-transform: translateY(-50px);
	-ms-transform: translateY(-50px);
	transform: translateY(-50px);
}

.cs-style-3 figcaption {
	height: 100px;
	width: 100%;
	top: auto;
	bottom: 0;
	opacity: 0;
	-webkit-transform: translateY(100%);
	-moz-transform: translateY(100%);
	-ms-transform: translateY(100%);
	transform: translateY(100%);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
	-moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
	transition: transform 0.4s, opacity 0.1s 0.3s;
}

.no-touch .cs-style-3 figure:hover figcaption,
.cs-style-3 figure.cs-hover figcaption {
	opacity: 1;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
	-moz-transition: -moz-transform 0.4s, opacity 0.1s;
	transition: transform 0.4s, opacity 0.1s;
}

.cs-style-3 figcaption a {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

/* Caption Style 4 */
.cs-style-4 li {
	-webkit-perspective: 1700px;
	-moz-perspective: 1700px;
	perspective: 1700px;
	-webkit-perspective-origin: 0 50%;
	-moz-perspective-origin: 0 50%;
	perspective-origin: 0 50%;
}

.cs-style-4 figure {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.cs-style-4 figure > div {
	overflow: hidden;
}

.cs-style-4 figure img {
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: -moz-transform 0.4s;
	transition: transform 0.4s;
}

.no-touch .cs-style-4 figure:hover img,
.cs-style-4 figure.cs-hover img {
	-webkit-transform: translateX(25%);
	-moz-transform: translateX(25%);
	-ms-transform: translateX(25%);
	transform: translateX(25%);
}

.cs-style-4 figcaption {
	height: 100%;
	width: 50%;
	opacity: 0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transform: rotateY(-90deg);
	-moz-transform: rotateY(-90deg);
	transform: rotateY(-90deg);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
	-moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
	transition: transform 0.4s, opacity 0.1s 0.3s;
}

.no-touch .cs-style-4 figure:hover figcaption,
.cs-style-4 figure.cs-hover figcaption {
	opacity: 1;
	-webkit-transform: rotateY(0deg);
	-moz-transform: rotateY(0deg);
	transform: rotateY(0deg);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
	-moz-transition: -moz-transform 0.4s, opacity 0.1s;
	transition: transform 0.4s, opacity 0.1s;
}

.cs-style-4 figcaption a {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

/* Caption Style 5 */
.cs-style-5 figure img {
	z-index: 10;
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: -moz-transform 0.4s;
	transition: transform 0.4s;
}

.no-touch .cs-style-5 figure:hover img,
.cs-style-5 figure.cs-hover img {
	-webkit-transform: scale(0.4);
	-moz-transform: scale(0.4);
	-ms-transform: scale(0.4);
	transform: scale(0.4);
}

.cs-style-5 figcaption {
	height: 100%;
	width: 100%;
	opacity: 0;
	-webkit-transform: scale(0.7);
	-moz-transform: scale(0.7);
	-ms-transform: scale(0.7);
	transform: scale(0.7);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	-moz-transition: -moz-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

.no-touch .cs-style-5 figure:hover figcaption,
.cs-style-5 figure.cs-hover figcaption {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

.cs-style-5 figure a {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

/* Caption Style 6 */
.cs-style-6 figure img {
	z-index: 10;
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: -moz-transform 0.4s;
	transition: transform 0.4s;
}

.no-touch .cs-style-6 figure:hover img,
.cs-style-6 figure.cs-hover img {
	-webkit-transform: translateY(-50px) scale(0.5);
	-moz-transform: translateY(-50px) scale(0.5);
	-ms-transform: translateY(-50px) scale(0.5);
	transform: translateY(-50px) scale(0.5);
}

.cs-style-6 figcaption {
	height: 100%;
	width: 100%;
}

.cs-style-6 figcaption h3 {
	margin-top: 60%;
}

.cs-style-6 figcaption a {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

/* Caption Style 7 */
.cs-style-7 li:first-child { z-index: 6; }
.cs-style-7 li:nth-child(2) { z-index: 5; }
.cs-style-7 li:nth-child(3) { z-index: 4; }
.cs-style-7 li:nth-child(4) { z-index: 3; }
.cs-style-7 li:nth-child(5) { z-index: 2; }
.cs-style-7 li:nth-child(6) { z-index: 1; }

.cs-style-7 figure img {
	z-index: 10;
}

.cs-style-7 figcaption {
	height: 100%;
	width: 100%;
	opacity: 0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: opacity 0.3s, height 0.3s, box-shadow 0.3s;
	-moz-transition: opacity 0.3s, height 0.3s, box-shadow 0.3s;
	transition: opacity 0.3s, height 0.3s, box-shadow 0.3s;
	box-shadow: 0 0 0 0px #2c3f52;
}

.no-touch .cs-style-7 figure:hover figcaption,
.cs-style-7 figure.cs-hover figcaption {
	opacity: 1;
	height: 130%;
	box-shadow: 0 0 0 10px #2c3f52;
}

.cs-style-7 figcaption h3 {
	margin-top: 86%;
}

.cs-style-7 figcaption h3,
.cs-style-7 figcaption span,
.cs-style-7 figcaption a {
	opacity: 0;
	-webkit-transition: opacity 0s;
	-moz-transition: opacity 0s;
	transition: opacity 0s;
}

.cs-style-7 figcaption a {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

.no-touch .cs-style-7 figure:hover figcaption h3,
.no-touch .cs-style-7 figure:hover figcaption span,
.no-touch .cs-style-7 figure:hover figcaption a,
.cs-style-7 figure.cs-hover figcaption h3,
.cs-style-7 figure.cs-hover figcaption span,
.cs-style-7 figure.cs-hover figcaption a {
	-webkit-transition: opacity 0.3s 0.2s;
	-moz-transition: opacity 0.3s 0.2s;
	transition: opacity 0.3s 0.2s;
	opacity: 1;
}

@media screen and (max-width: 31.5em) {
	.grid {
		padding: 10px 10px 100px 10px;
	}
	.grid li {
		width: 100%;
		min-width: 300px;
	}
}

.scrollable-element{
	scrollbar-color: white rgba(255, 255, 255, 1);
	;
}



.learn-body {
    overflow-x: scroll;
    height: 40vw;
    width: 100%;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
}

.image-track {
    display: flex;
    position: absolute;
    margin: 0;
    gap: 2vw;
    align-items: center;
    transform: translate(0%, 10%);
    left: 400px;
}



.home-learn-heading {
    display: flex;
    justify-content: center;
}



@media (max-width:1000px) {
/* 
    img {
        height: 42vw;
        width: 32vw;
    }
 */

    .learn-body {
        height: 50vw;
    }

    .image-track {
        gap: 3vw;
    }

    .home-component>div>h1 {
        font-size: 2rem;
    }

    .home-learn-heading {
        font-size: 2rem;
        margin-bottom: -10px;
    }
}
		.profile-in-resp{
			display: none;
		}
@media (max-width:500px) {
    /* img {
        height: 90vw;
        width: 65vw;
    } */
	.profile-in-resp{
		align-items: center;
		display: block;
		margin-bottom: 0 !important;
	}
	.image-in-resp{
		display: none;
	}

    .learn-body {
        height: 105vw;
    }

    .image-track {
        gap: 5vw;
        left: -40vw;
    }

    .home-component>div>h1 {
        font-size: 2rem;
    }

    .home-learn-heading {
        font-size: 2rem;
        margin-bottom: -10px;
    }

	.talkhead {
		font-size:2.5rem;
	}

}